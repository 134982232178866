import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {CommonModalState} from '@shared/editAssistant/common'
import compress from '@shared/util/compress'
import {OperableTextFormComponentType, textOperationFunctions} from '@shared/util/form-text-ops'
import StyledReactModal from '@shared/util/StyledReactModal'
import classNames from 'classnames'
import React, {RefObject, useCallback, useEffect} from 'react'
import {FileRejection, useDropzone} from 'react-dropzone'
import {StoreApi, UseBoundStore} from 'zustand'

type FileWithPreview = File & {preview?: string}

type ProcessState = 'unmodified' | 'resizing' | 'resized' | 'resize-failed'
type UploadState = 'not-uploaded' | 'uploading' | 'uploaded' | 'upload-failed' | 'file-exists'
type OverwriteMode = 'overwrite' | 'rename' | 'prevent'

const ERROR_CODE_ALREADY_EXISTS = 10

export interface UploadModalState {
    isUploadModalOpen: boolean
    processState: ProcessState
    currentFile?: FileWithPreview
    resizedFile?: File
    filenamePreview?: string
    warningMessage: string
    errorMessage: string
    uploadState: UploadState
    overwriteMode: OverwriteMode
    openUploadModal(): void
    closeUploadModal(): void
    clearUploadModal(): void
    setCurrentFile(file: File): void
    setWarningMessage(message: string): void
    setErrorMessage(message: string): void
    startResizing(): void
    finishResizing(resizedFile: File): void
    failCompress(msg: string): void
    failUpload(msg: string): void
    setFilenamePreview(filename: string): void
    setUploadState(state: UploadState): void
    setOverwriteMode(mode: OverwriteMode): void
}

export function createUploadModalState(
    set: StoreApi<UploadModalState>['setState'],
    get: StoreApi<UploadModalState>['getState']
): UploadModalState {
    return {
        isUploadModalOpen: false,
        processState: 'unmodified',
        currentFile: null,
        warningMessage: '',
        errorMessage: '',
        resizedFile: null,
        uploadState: 'not-uploaded',
        overwriteMode: 'prevent',
        openUploadModal() {
            set({isUploadModalOpen: true})
        },
        closeUploadModal() {
            set({isUploadModalOpen: false})
            get().clearUploadModal()
        },
        clearUploadModal() {
            set({
                currentFile: null,
                processState: 'unmodified',
                warningMessage: '',
                errorMessage: '',
                resizedFile: null,
                filenamePreview: '',
                uploadState: 'not-uploaded',
                overwriteMode: 'prevent',
            })
        },
        setCurrentFile(file: FileWithPreview) {
            set({currentFile: file})
        },
        setWarningMessage(message: string) {
            set({warningMessage: message})
        },
        setErrorMessage(message: string) {
            set({errorMessage: message})
        },
        startResizing() {
            set({
                processState: 'resizing',
                warningMessage: '画像を縮小中...',
                errorMessage: '',
                resizedFile: null,
                filenamePreview: null,
            })
        },
        finishResizing(resizedFile) {
            set({
                warningMessage: "ファイルサイズの上限を超えているため、縮小した画像を送信します。",
                processState: 'resized',
                resizedFile: resizedFile,
                filenamePreview: resizedFile.name,
            })
        },
        failCompress(msg) {
            set({
                warningMessage: '',
                errorMessage: msg,
                processState: 'resize-failed',
                resizedFile: null,
            })
        },
        failUpload(msg) {
            set({
                warningMessage: '',
                errorMessage: msg,
                uploadState: 'upload-failed',
            })
        },
        setFilenamePreview(filename: string) {
            set({filenamePreview: filename})
        },
        setUploadState(state: UploadState) {
            set({uploadState: state})
        },
        setOverwriteMode(mode: OverwriteMode) {
            set({overwriteMode: mode})
        }
    }
}

export interface UploadModalProps {
    uploadUrl: string
    maxFileSize: number
    attachFileInputName: string
}

export function UploadModal({useStore, editorRef, uploadModalProps, isCreating = false}: {
    useStore: UseBoundStore<StoreApi<UploadModalState & CommonModalState>>
    editorRef: RefObject<OperableTextFormComponentType>
    uploadModalProps: UploadModalProps
    isCreating?: boolean
}) {
    const {
        isUploadModalOpen, currentFile, closeUploadModal, setCurrentFile,
        warningMessage, errorMessage, setErrorMessage, clearUploadModal,
        startResizing, finishResizing, processState, resizedFile,
        failCompress, failUpload,
        filenamePreview, setFilenamePreview, uploadState, setUploadState,
        overwriteMode, setOverwriteMode,
    } = useStore()

    const {insertAtCursor} = textOperationFunctions(editorRef)

    const onDrop = useCallback((acceptedFiles: File[], fileRejections: FileRejection[]) => {
        clearUploadModal()

        if (fileRejections.length > 0) {
            setErrorMessage('アップロードできるのは1つの画像ファイルのみです。')
            return
        }

        if (acceptedFiles.length > 0) {
            setErrorMessage('')

            const file = acceptedFiles[0]
            setCurrentFile(Object.assign(file, {
                preview: URL.createObjectURL(file)
            }))

            if (file.size > uploadModalProps.maxFileSize) {
                startResizing()
                const compressTargetParam = {
                    maxFileSize: uploadModalProps.maxFileSize,
                    compressorQuality: 0.8,
                    compressorMaxWidth: 2048,
                    compressorMaxHeight: 2048,
                }
                compress(file, 1, compressTargetParam, (result) => {
                    finishResizing(result)
                }, (err) => {
                    failCompress('画像の縮小に失敗: ' + err.message)
                })
            } else {
                setFilenamePreview(file.name)
            }

        }
    }, [])

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        maxFiles: 1,
        accept: {
            'image/jpeg': ['.jpg', '.jpeg'],
            'image/png': ['.png'],
            'image/gif': ['.gif'],
            'image/webp': ['.webp'],
        }
    })

    useEffect(() => {
        // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
        return () => {
            if (currentFile && currentFile.preview) {
                URL.revokeObjectURL(currentFile.preview)
            }
        }
    }, [])

    const handleClickUpload = () => {
        if (
            isCreating
            || !currentFile
            || processState === 'resizing'
            || processState === 'resize-failed'
            || uploadState === 'uploading'
        ) {
            return
        }

        const formData = new FormData()
        if (processState === 'resized') {
            // ファイル名には file.name でなく result.name を使う。（大きい png は jpg になるし、gif が png になる環境もある。）
            formData.set(uploadModalProps.attachFileInputName, resizedFile, resizedFile.name)
        } else {
            formData.set(uploadModalProps.attachFileInputName, currentFile)
        }
        formData.set('overwriteMode', overwriteMode)

        setUploadState('uploading')

        fetch(uploadModalProps.uploadUrl, {
            method: 'POST',
            body: formData,
            headers: {
                'X-Requested-With': 'XMLHttpRequest'
            }
        }).then(response => response.json())
        .then(data => {
            if (data.hasOwnProperty('result') && data.result) {
                insertAtCursor(`&ref(${data.file});`)
                closeUploadModal()
            } else {
                if (data.hasOwnProperty('errorCode')) {
                    if (data.errorCode === ERROR_CODE_ALREADY_EXISTS) {
                        setUploadState('file-exists')
                    } else {
                        failUpload(data.msg)
                    }
                }
            }
        }).catch(() => {
            failUpload('画像の送信エラー')
        })
    }

    return (
        <StyledReactModal
            className="edit-assistant-modal upload-modal"
            isOpen={isUploadModalOpen} shouldReturnFocusAfterClose={false} shouldCloseOnOverlayClick={true}
            onRequestClose={() => {
                closeUploadModal()
            }}
        >
            <h3 className="modal-title">画像アップロード</h3>
            <div className="modal-body">
                {
                    isCreating
                    ? <p className="error-message">新規作成ページに直接ファイルをアップロードすることはできません。一度ページを作成してください。</p>
                    : <UploadContent
                        uploadState={uploadState}
                        getRootProps={getRootProps}
                        getInputProps={getInputProps}
                        isDragActive={isDragActive}
                        currentFile={currentFile}
                        filenamePreview={filenamePreview}
                        errorMessage={errorMessage}
                        warningMessage={warningMessage}
                        overwriteMode={overwriteMode}
                        setOverwriteMode={setOverwriteMode}
                    />
                }
            </div>
            <div className="buttons">
                <button className="ok-button" type="button" disabled={
                    isCreating
                    || !currentFile
                    || processState === 'resizing'
                    || processState === 'resize-failed'
                    || uploadState === 'uploading'
                    || (uploadState === 'file-exists' && overwriteMode === 'prevent')
                } onClick={handleClickUpload}>
                    {uploadState === 'file-exists' ? '再アップロード' : 'アップロード'}
                </button>
                <button type="button" onClick={() => closeUploadModal()}>キャンセル</button>
            </div>
        </StyledReactModal>
    )
}

function UploadContent({
    uploadState,
    getRootProps,
    getInputProps,
    isDragActive,
    currentFile,
    filenamePreview,
    errorMessage,
    warningMessage,
    overwriteMode,
    setOverwriteMode,
}: {
    uploadState: UploadState,
    getRootProps: () => any,
    getInputProps: () => any,
    isDragActive: boolean,
    currentFile: FileWithPreview | null,
    filenamePreview: string | null,
    errorMessage: string,
    warningMessage: string,
    overwriteMode: OverwriteMode,
    setOverwriteMode: (mode: OverwriteMode) => void,
}) {
    return (
        <>
            {
                uploadState === 'uploading' &&
                <div className="loading">
                    <FontAwesomeIcon icon={['fas', 'spinner']} pulse />
                </div>
            }
            {
                (uploadState === 'not-uploaded' || uploadState === 'upload-failed') &&
                <div className={classNames('upload-zone', {'dragging': isDragActive})} {...getRootProps()}>
                    <input {...getInputProps()} />
                    <p>
                        クリックでファイルを選択 または ここにドロップ
                    </p>
                </div>
            }
            <p>※ アップロードされたファイルはこのページに添付されます</p>
            <p>※ アップロード可能最大ファイルサイズは 512KB です。サイズを超過する画像は自動的に調整/変換されます。</p>
            {
                currentFile &&
                <div className="upload-preview">
                    <img
                        src={currentFile.preview}
                        alt="preview"
                        // Revoke data uri after image is loaded
                        onLoad={() => {
                            URL.revokeObjectURL(currentFile.preview)
                        }}
                    />
                    <p>{filenamePreview}</p>
                </div>
            }
            {
                errorMessage &&
                <p className="error-message">{errorMessage}</p>
            }
            {
                warningMessage &&
                <p className="warning-message">{warningMessage}</p>
            }
            {
                uploadState === 'file-exists' &&
                <div className="overwrite-mode">
                    <p className="already-existing-warning">同名のファイルが既に存在します。</p>
                    <div>
                        <label>
                            <input type="radio" name="overwriteMode" value="overwrite"
                                   onChange={() => setOverwriteMode('overwrite')}
                                   checked={overwriteMode === 'overwrite'}
                            />
                            上書きする（元のファイルは削除されます）
                        </label>
                    </div>
                    <div>
                        <label>
                            <input type="radio" name="overwriteMode" value="rename"
                                   onChange={() => setOverwriteMode('rename')}
                                   checked={overwriteMode === 'rename'}
                            />
                            このファイルの名前を変更する（ファイル名の末尾に数字が追加されます）
                        </label>
                    </div>
                </div>
            }
        </>
    )
}
