import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {UploadModal, UploadModalProps} from '@shared/editAssistant/UploadModal'
import React, {ReactNode, RefObject, SyntheticEvent, useEffect} from 'react'
import {ColorModal} from './ColorModal'
import {OperableTextFormComponentType, textOperationFunctions} from '../util/form-text-ops'
import {FaceImageButton} from './FaceImageButton'
import {FontSizeModal} from './FontSizeModal'
import {LinkModal} from './LinkModal'
import {NtbrModal} from './NtbrModal'
import {PluginModal} from './PluginModal'
import {useEditAssistantLocalStateUseStore} from './index'

interface EditAssistantButtonProps {
    onClick(e): void
    experimentalFeature: boolean
    children: ReactNode
    [prop: string]: any
}

interface EditAssistantButtonGroup {
    buttons: any[]
    inline: boolean
}

export function EditAssistant({inlineOnly, isCreating, uploadModalProps, editorRef}: {
    inlineOnly: boolean,
    isCreating?: boolean,
    uploadModalProps?: UploadModalProps
    editorRef: RefObject<OperableTextFormComponentType>
}) {
    const {
        selectionReplace,
        insertAtCursor,
        surroundWith,
    } = textOperationFunctions(editorRef)

    const useStore = useEditAssistantLocalStateUseStore()
    const {
        openColorModal, openFontSizeModal, openNtbrModal,
        openLinkModal, openPluginModal, openUploadModal, focus, setFocus
    } = useStore()

    const handleClickDecoration = clickAction((element) => {
        surroundWith(element.dataset.code, element.dataset.code)
    })

    // モーダル上の操作でちゃんとフォーカスされてくれないため
    useEffect(() => {
        if (focus) {
            editorRef.current.focus()
            setFocus(false)
        }
    }, [focus])

    const buttonGroups: EditAssistantButtonGroup[] = [
        {
            buttons: [
                <EditAssistantButton onClick={() => openColorModal()} experimentalFeature={false}>
                    <FontAwesomeIcon icon={['fas', 'eye-dropper']}/>
                </EditAssistantButton>,

                <EditAssistantButton onClick={() => openFontSizeModal()} experimentalFeature={false}>
                    <FontAwesomeIcon icon={['fas', 'text-height']}/>
                </EditAssistantButton>,

                <EditAssistantButton onClick={handleClickDecoration} experimentalFeature={false} data-code="''">
                    <FontAwesomeIcon icon={['fas', 'bold']}/>
                </EditAssistantButton>,
                // イタリックとアンダーラインは使えないようにしておく 後で戻す可能性あり
                // <EditAssistantButton onClick={handleClickDecoration} data-code="'''">
                //     <FontAwesomeIcon icon={['fas', 'italic']}/>
                // </EditAssistantButton>,
                <EditAssistantButton onClick={handleClickDecoration} experimentalFeature={false} data-code="%%">
                    <FontAwesomeIcon icon={['fas', 'strikethrough']}/>
                </EditAssistantButton>,
                // <EditAssistantButton onClick={handleClickDecoration} data-code="%%%">
                //     <FontAwesomeIcon icon={['fas', 'underline']}/>
                // </EditAssistantButton>,
                <EditAssistantButton onClick={() => openNtbrModal()} experimentalFeature={false}>
                    <FontAwesomeIcon icon={['fas', 'block-question']} size="lg"/>
                </EditAssistantButton>,
            ],
            inline: true,
        },
        {
            buttons: [
                <EditAssistantButton onClick={() => openLinkModal()} experimentalFeature={false}>
                    <FontAwesomeIcon icon={['fas', 'link']}/>
                </EditAssistantButton>,
                <EditAssistantButton onClick={() => insertAtCursor('&attachref;')} experimentalFeature={false}>
                    <FontAwesomeIcon icon={['fas', 'paperclip']}/>
                </EditAssistantButton>,
                ...(uploadModalProps ? [
                    <EditAssistantButton onClick={() => openUploadModal()} experimentalFeature={false}>
                        <FontAwesomeIcon icon={['far', 'image']}/>
                    </EditAssistantButton>
                ] : []),
            ],
            inline: true,
        },
        {
            buttons: [
                <EditAssistantButton onClick={() => insertAtCursor('&br;')} experimentalFeature={false}>
                    <img alt="改行"
                         src="data:image/svg+xml;charset=utf8,%3Csvg%20id%3D%22%E3%83%AC%E3%82%A4%E3%83%A4%E3%83%BC_1%22%20data-name%3D%22%E3%83%AC%E3%82%A4%E3%83%A4%E3%83%BC%201%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20100%20100%22%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill%3A%231a1a1a%3Bstroke%3A%231a1a1a%3Bstroke-miterlimit%3A10%3B%7D%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cpath%20class%3D%22cls-1%22%20d%3D%22M85.21%2C25.36h-14a3%2C3%2C0%2C0%2C0-3%2C3v57h12a8%2C8%2C0%2C0%2C0%2C8-8v-49A3%2C3%2C0%2C0%2C0%2C85.21%2C25.36Z%22%2F%3E%3Cpath%20class%3D%22cls-1%22%20d%3D%22M47.52%2C57.66a2%2C2%2C0%2C0%2C0-3-1.78L12.84%2C73a2%2C2%2C0%2C0%2C0%2C0%2C3.5L44.14%2C94.38a2%2C2%2C0%2C0%2C0%2C3-1.72l.08-7.3h20v-20H47.43Z%22%2F%3E%3C%2Fsvg%3E"/>
                </EditAssistantButton>,
                <EditAssistantButton className="assist-button char-code"
                                     onClick={() => selectionReplace(replaceByCharCode)}
                                     experimentalFeature={false}>
                    <span>&#</span>
                </EditAssistantButton>,
                <FaceImageButton useStore={useStore} editorRef={editorRef}>
                    <FontAwesomeIcon icon={['far', 'face-laugh']}/>
                </FaceImageButton>
            ],
            inline: true,
        },
        {
            buttons: [
                <>
                    <EditAssistantButton style={{width: 'fit-content'}} onClick={() => openPluginModal()}
                                         experimentalFeature={false}>
                        プラグイン
                    </EditAssistantButton>
                </>,
            ],
            inline: false,
        }
    ]

    return <div className="wiki-edit-assistant">
        <ul>
            {buttonGroups.map((buttonGroup, index) => {
                return (!inlineOnly || buttonGroup.inline) ?
                    <li key={index}>
                        <ul className="button-group">
                            {buttonGroup.buttons.map((button, index2) => <li key={index + "-" + index2}>{button}</li>)}
                        </ul>
                    </li>
                    : ''
            })}
        </ul>
        <ColorModal useStore={useStore} editorRef={editorRef}/>
        <FontSizeModal useStore={useStore} editorRef={editorRef}/>
        <LinkModal useStore={useStore} editorRef={editorRef}/>
        <NtbrModal useStore={useStore} editorRef={editorRef}/>
        <PluginModal useStore={useStore} editorRef={editorRef}/>
        {
            uploadModalProps
            && <UploadModal useStore={useStore} editorRef={editorRef} isCreating={isCreating} uploadModalProps={uploadModalProps}/>
        }
    </div>
}

function EditAssistantButton({onClick, experimentalFeature, children, ...props}: EditAssistantButtonProps) {
    return !experimentalFeature || window.globalParamsContainer.experimentalFeature
        ? <button type="button" className="assist-button" onClick={onClick} {...props}>
            {children}
        </button>
        : <></>
}

function clickAction(action: (element?: HTMLElement) => void) {
    // ちょっと片付け無理してるふしはある
    // preact で currentTarget を辿れる正しい型ってなんだろう
    return (event: SyntheticEvent<HTMLElement>) => {
        event.preventDefault()
        action(event.currentTarget)
    }
}

const replaceByCharCode = (str: string) => {
    let j = ""
    for (let n = 0; n < str.length; n++) {
        j += ("&#" + str.charCodeAt(n) + ";")
    }

    return j
}
