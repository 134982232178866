import Compressor from 'compressorjs'

interface CompressTargetParams {
    maxFileSize: number
    compressorQuality: number
    compressorMaxWidth: number
    compressorMaxHeight: number
}

export default function compress(
    file: File,
    resizeFactor: number,
    ctp: CompressTargetParams,
    onSuccess: (result: File) => void,
    onError: (err: Error) => void,
) {
    new Compressor(file, {
        quality: ctp.compressorQuality,
        maxWidth: ctp.compressorMaxWidth / resizeFactor,
        maxHeight: ctp.compressorMaxHeight / resizeFactor,
        success: (result: File) => {
            // サイズ上限に収まるまで縮小係数を掛けて再帰的に試す
            if (result.size > ctp.maxFileSize) {
                compress(file, resizeFactor * Math.sqrt(2), ctp, onSuccess, onError)
                return
            }
            onSuccess(result)
        },
        error: (err) => {
            onError(err)
        },
    })
}
